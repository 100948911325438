import React, { memo, useEffect, useState } from "react"

import { Text, Divider, Box, Image, Flex, AspectRatio } from "@chakra-ui/react"
import { InstagramTaggedProductThumbnails } from "@app/components/Sections/Instagram/InstagramTaggedProductThumbnails"
import { FormButton } from "@app/components/Form/FormButton"
import { SwatchFull, useSwatch } from "@app/hooks/useSwatch"
import { useCore } from "@app/hooks/useCore"

import { navigate } from "gatsby"
import type { ProductProps } from "@root/types/global"

type Props = {
  taggedProducts: ProductProps[]
  setIsLoaded: (state: boolean) => void
  shopButtonText: string
}

export const InstagramActiveTaggedProduct: React.FC<Props> = memo(({ taggedProducts, setIsLoaded, shopButtonText }) => {
  const {
    helpers: { formatPrice },
  } = useCore()
  const { getSwatches } = useSwatch()
  const { formatActiveProduct } = useSwatch()
  const [allSwatches, setAllSwatches] = useState([] as Array<SwatchFull>)
  const [taggedProductIndex, setTaggedProductIndex] = useState(0)
  const [activeProduct, setActiveProduct] = useState<SwatchFull | null>(
    formatActiveProduct(taggedProducts[0], null, true, false) as SwatchFull
  )

  const handleNavigate = (url: string) => navigate(url)

  useEffect(() => {
    ;(async () => {
      const swatches = (await getSwatches(taggedProducts[taggedProductIndex])) as Array<SwatchFull>
      const activeSwatch = swatches ? swatches?.find(swatch => swatch.handle == taggedProducts[taggedProductIndex].handle) : null
      setAllSwatches(swatches)
      activeSwatch && setActiveProduct(activeSwatch)
      setIsLoaded(true)
    })()

    if (taggedProducts.length === 0) setIsLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taggedProducts, taggedProductIndex])

  useEffect(() => {
    setTaggedProductIndex(0)
  }, [taggedProducts])

  return (
    <Box>
      {allSwatches?.length > 0 && activeProduct ? (
        <Flex mt={{ base: "6", md: "0" }}>
          <Flex height="30" width="30">
            <AspectRatio bg="grey.cloud" ratio={1 / 1} w="full" minWidth="30">
              <Image src={activeProduct.images?.[0].src} height="full" width="full" objectFit="cover" />
            </AspectRatio>
          </Flex>

          <Flex ml="4" direction="column">
            <Text size="xl" fontSize={{ base: "sm", md: "xl" }} mb={{ base: "4", md: "2" }}>
              {activeProduct?.product?.title || activeProduct?.title}
            </Text>

            <Flex direction="row">
              {allSwatches.map(swatchProduct => (
                <Flex key={swatchProduct.handle} mr={2.25} mt={2.25}>
                  <Flex
                    border="1px solid"
                    borderRadius="full"
                    borderColor={
                      swatchProduct.handle === activeProduct.handle
                        ? swatchProduct.swatch?.colour
                          ? swatchProduct.swatch?.colour
                          : "grey.fog"
                        : "transparent"
                    }
                    cursor="pointer"
                    onClick={async () => {
                      setActiveProduct(swatchProduct)
                    }}
                    title={swatchProduct.name}
                    _hover={
                      swatchProduct.handle !== activeProduct.handle
                        ? {
                            borderColor: swatchProduct.swatch?.colour ? swatchProduct.swatch?.colour : "grey.fog",
                          }
                        : {}
                    }
                  >
                    <Flex border="1px solid" borderRadius="full" borderColor="transparent" pos="relative" overflow="hidden" h={3} w={3}>
                      <Box
                        pos="absolute"
                        pointerEvents="none"
                        inset={0}
                        bgColor={swatchProduct.swatch?.colour || "grey.fog"}
                        bgImage={swatchProduct.image ? `url(${swatchProduct.image})` : undefined}
                        bgPosition="center"
                        bgSize="cover"
                      />
                    </Flex>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>

          <Box ml={{ base: "2", md: "4" }}>
            <Text size="xl" fontSize={{ base: "sm", md: "xl" }} fontWeight="bold">
              {`$${formatPrice(
                activeProduct?.product?.priceRange?.minVariantPrice?.amount ||
                  activeProduct?.priceRange?.minVariantPrice?.amount ||
                  activeProduct?.product?.presentmentPriceRanges?.minVariantPrice?.amount ||
                  activeProduct?.presentmentPriceRanges?.minVariantPrice?.amount
              )}`}
            </Text>
          </Box>
        </Flex>
      ) : null}

      {activeProduct?.url && activeProduct.handle && (
        <>
          {shopButtonText && (
            <FormButton
              size="md"
              variant="primary"
              background="grey.darker"
              border="grey.darker"
              w="full"
              mt="6"
              onClick={() => {
                handleNavigate(activeProduct.url)
              }}
            >
              {shopButtonText}
            </FormButton>
          )}

          <InstagramTaggedProductThumbnails
            taggedProducts={taggedProducts}
            activeProduct={activeProduct}
            setActiveProduct={setActiveProduct}
            setTaggedProductIndex={setTaggedProductIndex}
          />
          <Divider borderBottomColor="grey.fog" my={6} />
        </>
      )}
    </Box>
  )
})
