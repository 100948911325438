import React, { memo, useState, useEffect } from "react"
import {
  Container,
  useBreakpointValue,
  Heading,
  Grid,
  GridItem,
  AspectRatio,
  Skeleton,
  SkeletonText,
  Box,
  useDisclosure,
} from "@chakra-ui/react"

import { withSection } from "@app/hoc/Section"

import { useFunctions } from "@app/hooks/useFunctions"
import { InstagramSlider } from "./InstagramSlider"

import type { Section } from "@root/types/global"
import { InstagramModal } from "./InstagramModal"
import type { InstagramImage } from "@root/types/global"

type Props = Section & GatsbyTypes.SanitySectionFeaturedProducts

const Instagram: React.FC<Props> = withSection(
  memo(({ handleTrackingClick, tag, title, isHomepage, albumName, shopButton }) => {
    const { callFunction } = useFunctions()
    const slidesPerView = useBreakpointValue({ base: 1, sm: 3 }) || 1
    const [posts, setPosts] = useState<InstagramImage[] | undefined>(undefined)
    const [activeModalPost, setActiveModalPost] = useState<number>(0)
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0)
    const { isOpen, onToggle } = useDisclosure()
    const perPageValue = 10

    useEffect(() => {
      const fetchData = async () => {
        // Calculate the currentPage to fetch based on the current length of posts.
        const currentPage = Math.ceil((posts?.length ?? 0) / perPageValue) + 1

        const {
          status,
          body: { response },
        } = await callFunction("instagram", { page: currentPage, perPage: perPageValue, albumName })

        if (status === "success" && response.images.length > 0) {
          setPosts(prevPosts => {
            const newImages = response.images.filter(
              (newImage: InstagramImage) => !prevPosts?.find(prevImage => prevImage.image_id === newImage.image_id)
            )
            return prevPosts ? [...prevPosts, ...newImages] : newImages
          })
        }
      }

      // Use the maximum index of the slider and modal to determine when to fetch more posts
      const maxIndex = Math.max(activeSlideIndex, activeModalPost)
      const thresholdIndex = Math.max((posts?.length ?? 0) - slidesPerView * 2, 0)
      const shouldFetchMore = posts?.length < perPageValue || maxIndex >= thresholdIndex

      if (shouldFetchMore) {
        fetchData()
      }
    }, [activeSlideIndex, activeModalPost, callFunction, posts, slidesPerView, perPageValue, albumName])

    const handlePostClick = (postIndex: number) => {
      handleTrackingClick()
      onToggle()
      setActiveModalPost(postIndex)
    }

    const handleSliderChange = (realIndex: number) => {
      setActiveSlideIndex(realIndex)
    }

    return posts && posts?.length > 0 ? (
      <>
        <Container as="section" py={{ base: 5, md: 6, lg: 8 }}>
          {title && (
            <Heading
              as={tag}
              color="grey.coal"
              mb={6}
              size="h3"
              fontWeight={500}
              textTransform="initial"
              letterSpacing="ample"
              textAlign={{ base: "left", md: "center" }}
              // Always use "2xl" size for mobile homepage
              // https://app.productive.io/1476-dotdev/tasks/2681468
              fontSize={isHomepage ? "3xl" : ["2xl", "3xl"]}
            >
              {title}
            </Heading>
          )}

          <InstagramSlider
            posts={posts}
            handlePostClick={handlePostClick}
            handleSliderChange={handleSliderChange}
            slidesPerView={slidesPerView}
          />
        </Container>

        <InstagramModal
          onToggle={onToggle}
          isOpen={isOpen}
          activeModalPost={activeModalPost}
          posts={posts}
          setActiveModalPost={setActiveModalPost}
          shopButtonText={shopButton}
        />
      </>
    ) : (
      <Container as="section" py={{ base: 5, md: 6, lg: 8 }}>
        <Box>
          <Grid
            gap={{ base: 0, md: 5 }}
            gridAutoFlow="column"
            mx={0}
            pl="6.25"
            overflowX={{ base: "auto", md: "unset" }}
            templateColumns={{ base: "repeat(1, minmax(0, 1fr))", md: "repeat(3, minmax(0, 1fr))" }}
          >
            {Array.from(Array(4).keys()).map((_, index) => (
              <GridItem key={index} colSpan={1} pr={{ base: 5, md: 0 }}>
                <AspectRatio ratio={1 / 1} mb={6}>
                  <Skeleton />
                </AspectRatio>
                <SkeletonText w="full" mb={4} />
              </GridItem>
            ))}
          </Grid>
        </Box>
      </Container>
    )
  })
)

export default Instagram
