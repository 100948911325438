import React, { memo, useEffect, useState } from "react"

import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  AspectRatio,
  Image,
  Flex,
  Fade,
  useBreakpointValue,
} from "@chakra-ui/react"
import { Icon } from "@app/components/Icon"
import { InstagramActiveTaggedProduct } from "@app/components/Sections/Instagram/InstagramActiveTaggedProduct"

import { FormButton } from "@app/components/Form/FormButton"
import { useShopify } from "@app/hooks/useShopify"
import { Loader } from "@app/components/Loader/Loader"
import type { ProductProps } from "@root/types/global"

type Props = {
  onToggle: () => void
  isOpen: boolean
  posts: any
  activeModalPost: number
  setActiveModalPost: (index: number) => void
  loop?: boolean
  shopButtonText: string
}

export const InstagramModal: React.FC<Props> = memo(
  ({ onToggle, isOpen, posts, activeModalPost, setActiveModalPost, loop = false, shopButtonText }) => {
    const { getProducts } = useShopify()
    const modalSize = useBreakpointValue({ base: "full", md: "md" }) || "full"
    const postData = posts[activeModalPost]
    const img = postData?.original_image_url
    const content = postData?.post?.content
    const taggedProductHandles = postData?.tagged_products?.map((taggedProduct: ProductProps) => taggedProduct.product_url.split("/").pop())
    const [taggedProducts, setTaggedProducts] = useState<Array<ProductProps> | null>(null)
    const [lastFetchedPostIndex, setLastFetchedPostIndex] = useState<number | null>(null)
    const [isLoaded, setIsLoaded] = useState(!!taggedProductHandles)

    useEffect(() => {
      // Fetch products if it's a new post or if the modal is opened for the first time
      if (isOpen && lastFetchedPostIndex !== activeModalPost && taggedProductHandles.length > 0) {
        const fetchProducts = async () => {
          setIsLoaded(false)
          const products = await getProducts({ firstImages: 1, firstVariants: 1, handles: taggedProductHandles })
          setTaggedProducts(products)
          setLastFetchedPostIndex(activeModalPost)
        }

        fetchProducts()
      }
    }, [isOpen, activeModalPost, taggedProductHandles, getProducts, lastFetchedPostIndex])

    const handleNextPost = () => {
      if (taggedProductHandles.length) setIsLoaded(false)
      const newIndex = activeModalPost + 1 < posts.length ? activeModalPost + 1 : loop ? 0 : activeModalPost
      setActiveModalPost(newIndex)
    }

    const handlePrevPost = () => {
      if (taggedProductHandles.length) setIsLoaded(false)
      const newIndex = activeModalPost - 1 >= 0 ? activeModalPost - 1 : loop ? posts.length - 1 : activeModalPost
      setActiveModalPost(newIndex)
    }

    const isNextDisabled = activeModalPost === posts.length - 1
    const isPrevDisabled = activeModalPost === 0

    return (
      <Modal
        onClose={onToggle}
        isOpen={isOpen}
        isCentered
        motionPreset="slideInBottom"
        scrollBehavior="inside"
        size={modalSize}
        returnFocusOnClose={false}
      >
        <ModalOverlay background="rgba(67, 66, 62, 0.5)" /> {/* grey.coal colour adjusted for rgba */}
        <ModalContent>
          <Box display={{ base: "none", md: "block" }}>
            <Flex
              justifyContent="space-between"
              position="absolute"
              width="122%"
              left="-11%"
              top="50%"
              transform="translateY(-50%)"
              px={4}
              zIndex="overlay"
              pointerEvents="none"
            >
              <ModalPrevNext
                handleNextPost={handleNextPost}
                handlePrevPost={handlePrevPost}
                isNextDisabled={isNextDisabled}
                isPrevDisabled={isPrevDisabled}
              />
            </Flex>
          </Box>

          <ModalBody alignItems="center" justifyContent="flex-start" padding={{ base: "0", md: "15" }}>
            <Box display={{ base: "none", md: "block" }}>
              <ModalCloseButton
                background="rgba(255, 255, 255, 0.4)"
                borderRadius="100%"
                height="12"
                width="12"
                top="-9%"
                right="-5%"
                _hover={{ background: "transparent", color: "grey.coal" }}
              />
            </Box>

            {!isLoaded && (
              <Box w="100%" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
                <Loader />
              </Box>
            )}

            <Fade in={isLoaded}>
              <Flex direction={{ base: "column", md: "row" }} w="100%">
                <Flex flex="1">
                  <AspectRatio bg="grey.cloud" ratio={454 / 601} w="full">
                    <>
                      <Flex
                        flexDirection="column"
                        width="100%"
                        display={{ base: "flex !important", md: "none !important" }}
                        zIndex="overlay"
                        alignItems="flex-end !important"
                        justifyContent="flex-end !important"
                      >
                        <ModalCloseButton
                          background="rgba(255, 255, 255, 0.4)"
                          borderRadius="100%"
                          height="12"
                          width="12"
                          top="6"
                          right="4"
                          _hover={{ background: "transparent", color: "grey.coal" }}
                        />
                        <Flex px={4} marginBottom="4">
                          <ModalPrevNext
                            handleNextPost={handleNextPost}
                            handlePrevPost={handlePrevPost}
                            isNextDisabled={isNextDisabled}
                            isPrevDisabled={isPrevDisabled}
                          />
                        </Flex>
                      </Flex>

                      <Image src={img} maxWidth="1100px" />
                    </>
                  </AspectRatio>
                </Flex>
                <Flex flex="1" paddingLeft={{ base: "3.7", md: "3.7" }} paddingRight={{ base: "3.7", md: "0" }} direction="column">
                  {taggedProducts && (
                    <InstagramActiveTaggedProduct
                      taggedProducts={taggedProducts}
                      setIsLoaded={setIsLoaded}
                      shopButtonText={shopButtonText}
                    />
                  )}
                  {content && <Box paddingBottom={{ base: "20", md: "0" }}>{content}</Box>}
                </Flex>
              </Flex>
            </Fade>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
)

type ModalPrevNextProps = {
  handleNextPost: () => void
  handlePrevPost: () => void
  isNextDisabled: boolean
  isPrevDisabled: boolean
}

const ModalPrevNext: React.FC<ModalPrevNextProps> = memo(({ handleNextPost, handlePrevPost, isNextDisabled, isPrevDisabled }) => {
  return (
    <>
      <FormButton
        id="modal-prev"
        p={3}
        variant="ghost"
        onClick={() => handlePrevPost()}
        isDisabled={isPrevDisabled}
        background="rgba(255, 255, 255, 0.4)"
        borderRadius="100%"
        width="14"
        height="14"
        pointerEvents="auto"
      >
        <Icon name="chevron-left" width={7} />
      </FormButton>
      <FormButton
        id="modal-next"
        p={3}
        variant="ghost"
        onClick={() => handleNextPost()}
        isDisabled={isNextDisabled}
        background="rgba(255, 255, 255, 0.4)"
        borderRadius="100%"
        width="14"
        height="14"
        pointerEvents="auto"
        marginLeft={{ base: "4", md: "0" }}
      >
        <Icon name="chevron-right" width={7} />
      </FormButton>
    </>
  )
})
