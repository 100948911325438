import React, { memo } from "react"
import { AspectRatio, Box, Flex, Image } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation } from "swiper"
import "swiper/css"
import { FormButton } from "@app/components/Form/FormButton"
import { Icon } from "@app/components/Icon"
import { useGlobalContext } from "@app/providers/global"
import type { InstagramImage } from "@root/types/global"

type Props = {
  handlePostClick: (postIndex: number) => void
  handleSliderChange: (realIndex: number, activeIndex: number) => void
  posts: InstagramImage[]
  slidesPerView: number
}

SwiperCore.use([Navigation])

export const InstagramSlider: React.FC<Props> = memo(({ handleSliderChange, handlePostClick, posts, slidesPerView }) => {
  const { additionalPrevious, additionalNext } = useGlobalContext()

  return (
    <>
      <Flex flexDirection="column" alignItems="stretch" justifyContent="flex-start">
        <Box alignSelf="flex-end" mt={-16} w={100}>
          <Flex alignItems="center" justifyContent="flex-end">
            <FormButton id="insta-left" p={3} title={additionalPrevious} variant="ghost">
              <Icon name="chevron-left" width={5} />
            </FormButton>
            <FormButton id="insta-right" p={3} title={additionalNext} variant="ghost">
              <Icon name="chevron-right" width={5} />
            </FormButton>
          </Flex>
        </Box>
        <Box
          w="full"
          overflow="hidden"
          marginTop={{ base: 6, md: 0 }}
          sx={{
            ".swiper": { w: "full", cursor: "pointer" },
            ".swiper-wrapper": { display: "-webkit-box" },
            ".swiper-slide": { overflow: "hidden" },
            ".swiper-button-disabled": { opacity: 0, visibility: "hidden" },
          }}
        >
          <Swiper
            key={`swiper-${posts.length}`}
            navigation={{ prevEl: "#insta-left", nextEl: "#insta-right" }}
            slidesPerView={slidesPerView}
            direction="horizontal"
            spaceBetween={20}
            onSlideChange={({ realIndex, activeIndex }) => handleSliderChange(realIndex, activeIndex)}
          >
            {posts.map((post: InstagramImage, index: number) => (
              <SwiperSlide key={`${post.image_id}-index`} onClick={() => handlePostClick(index)}>
                <AspectRatio bg="grey.cloud" ratio={1 / 1} w="full">
                  <Image src={post.original_image_url} />
                </AspectRatio>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Flex>
    </>
  )
})
