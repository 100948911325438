import React, { memo } from "react"

import { Box, Image, Flex } from "@chakra-ui/react"
import type { ProductProps } from "@root/types/global"

type Props = {
  taggedProducts: ProductProps[]
  setActiveProduct: (product: any) => void
  activeProduct: any
  setTaggedProductIndex: (index: number) => void
}

export const InstagramTaggedProductThumbnails: React.FC<Props> = memo(
  ({ taggedProducts, setActiveProduct, activeProduct, setTaggedProductIndex }) => {
    return taggedProducts.length > 1 ? (
      <Flex mt="6">
        {taggedProducts.map((product: ProductProps, index: number) => (
          <Box
            key={product.handle}
            ml={index !== 0 ? 4 : 0}
            cursor="pointer"
            border="1px"
            borderColor={(activeProduct?.title || activeProduct?.product?.title) === product.title ? "black" : "transparent"}
            onClick={() => {
              setTaggedProductIndex(index)
            }}
          >
            <Image src={product.images?.[0]?.src} width="16" height="16" objectFit="cover" onClick={() => setActiveProduct(product)} />
          </Box>
        ))}
      </Flex>
    ) : null
  }
)
